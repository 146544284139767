@import 'variables';

#client-nav-item {
  border: 1px solid black;
  border-top: 0;
  border-bottom: 0;
  height: $navbar-content-height;
  padding: 0 1em;

  /* Flex Styling */
  display: flex;
  align-content: center;

  #client-button {
    padding: 0;
  }

  &:active,
  &:focus,
  &:hover {
    #client-button {
      color: $teal;
    }
  }
}
