.feed-index-selector {
  label {
    display: none;
  }

  .w24-select {
    display: inline-block;
    width: 100px !important;
  }
}
