@import 'variables';

@mixin override-ltr {
  .public-DraftStyleDefault-ltr {
    text-align: inherit;
  }
}

.edit-text-controls {
  .text-control-editor {
    background-color: $white;
    border-radius: $default-radius;
    color: $gray-800;
    height: 168px;
    margin-bottom: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px;
    width: 100%;

    *::selection {
      background-color: $teal;
      color: $white;
    }

    span {
      font-size: 18px !important;
    }
  }

  .DraftEditor-root {
    height: 100%;
  }

  .type-color-controls {
    display: flex;
  }

  .md-align-left {
    @include override-ltr;
    text-align: left;
  }

  .md-align-center {
    @include override-ltr;
    text-align: center;
  }

  .md-align-right {
    @include override-ltr;
    text-align: right;
  }

  .md-align-justify {
    @include override-ltr;
    text-align: center;
  }
}
