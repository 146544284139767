@import 'variables';

.new-clip-content-list {
  .content-items-scrollable {
    margin-bottom: 5em;
  }

  .content-item-card {
    float: left;
    margin: 0 .75em 1.5em .75em;
    width: $thumbnail-max-width;
    height: $thumbnail-max-height;
  }

  .clip-item {
    background-color: $gray-800;
    border: 1px solid $content-item-text-border-color;
    border-radius: $default-radius;
    margin-bottom: 0;
  }

  .file-upload {
    .upload-zone {
      height: $thumbnail-max-height;
    }
  }
}
