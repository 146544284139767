@import 'variables';

.w24-navbar-control {
  font-size: .875em;
  height: $navbar-content-height;
  margin: 0;
  padding: 12px 1.5em;

  /* Flex Styles */
  display: flex;
  align-items: center;

  &:after {
    margin-left: 1em;
  }
}

@media (min-width: 0px) and (max-width: 580px) {
  .w24-navbar-control {
    font-size: .75em;
    padding: 0;
  }
}
