@import 'variables';

$dark: (
  arrow-color: $gray-200,
  bg-color: $gray-700,
  border-color: $gray-700,
  color: $gray-200,
  disabled-bg-color: $gray-700,
  focus-color: $primary,
  focused-bg-color: lighten($gray-700, 20%),
  multi-border-color: $primary,
  option-color: $gray-300,
  selected-bg-color: lighten($gray-700, 10%),
);

$light: (
  arrow-color: $gray-800,
  bg-color: $white,
  border-color: $gray-300,
  color: $gray-800,
  disabled-bg-color: $gray-100,
  focus-color: $primary,
  focused-bg-color: darken($white, 20%),
  multi-border-color: $primary,
  option-color: $gray-500,
  selected-bg-color: darken($white, 10%),
);

$themes: (
  dark: $dark,
  light: $light
);

.w24-select {
  line-height: 1.5;

  .w24-select__control {
    border-width: 0;
  }
}

@each $theme-name, $theme in $themes {
  .w24-select.theme-#{$theme-name} {
    .w24-select__control {
      background-color: map-get($theme, 'bg-color');
    }

    .w24-select__control--is-focused {
      border-color: $primary;
    }

    .w24-select__single-value {
      color: map-get($theme, 'color');
    }

    .w24-select__dropdown-indicator {
      color: map-get($theme, 'arrow-color');
    }

    .w24-select__menu {
      background-color: map-get($theme, 'bg-color');
      border: 1px solid map-get($theme, 'border-color');
    }

    .w24-select__option {
      color: map-get($theme, 'option-color');
    }

    .w24-select__option--is-selected {
      background-color: $primary;
      color: $black;
    }

    .w24-select__option--is-focused {
      background-color: map-get($theme, 'focused-bg-color');
    }
  }
}
