@import 'variables';

#product-nav-item {
  border: 0;
  border-right: 1px solid black;
  height: $navbar-content-height;
  padding: 0 1em;

  #product-nav-button {
    padding: 0;
  }

  .product-nav-label {
    line-height: 1em;
    margin-right: 1em;
    text-align: left;
  }

  .product-name-label {
    font-size: 8px;
    text-transform: uppercase;
  }

  &:active,
  &:focus,
  &:hover {
    .product-name-label {
      color: $teal;
      font-size: .875em;
    }

    .product-name {
      font-size: 8px;
    }
  }
}
