@import 'variables';

.content-items {
  .content-items-scrollable {
    margin-bottom: 5em;
  }

  .content-item-card {
    float: left;
    height: $thumbnail-max-width; // We want this card to be a square, so set it to the same as the width.
    margin: 0 .75em 1.5em .75em;
    width: $thumbnail-max-width;
  }

  .content-item {
    margin-bottom: 0;
  }
}
