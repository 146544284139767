@import 'variables';

#password-reset-form {
    margin-top: 6em;
    @media screen and (max-width: map-get($grid-breakpoints, "md")) {
      margin-top: 4em;
  }

  .instructions-format {
    margin: 10px;
    margin-bottom: 50px;
    text-align: center;
  }

  .password-reset-form-footer {
    margin-top: 2em;
    text-align: center;
  }

  .password-reset-form-alert {
    margin-top: 20px;
  }
}

