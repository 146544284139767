@mixin w24-status-text {
  width: 100%;
  padding: 1em .5em;
  font-size: 1.375em;
  color: $gray-400;
  margin: 0;
  text-align: center;

  &.small-text {
    font-size: 1em;
    padding: .5em 1em;
  }
}
