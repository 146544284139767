@import 'variables';
@import 'mixins/w24-transparent-button';

.content-item {
  border-radius: $default-radius;
  overflow-y: hidden;
  background-color: $content-item-text-bg-color;

  &.non-editable {
    .content-item-button {
      &:hover, &:focus {
        cursor: auto;
      }
    }
  }

  .dropdown-item {
    .fa,
    .fad {
      margin-right: .5em;
      width: 20px;
    }
  }

  .content-item-text {
    font-size: .875em;
    color: $content-item-text-color;
    margin-bottom: .5em;
    line-height: 1.25;
  }

  .content-item-subtext {
    color: $content-item-subtext-color;
    font-size: .75em;
    margin: 0;
  }

  .content-item-button {
    @include w24-transparent-button;
    display: block;
    &:hover, &:focus {
      text-decoration: none;
      .content-item-image-overlay {
        display: flex;
      }
    }
  }

  .content-item-preview-button {
    border: none;
    background-color: transparent;
    font-size: 3.5em;
    color: $teal;
    padding: 0;
    &:hover, &:focus {
      cursor: pointer;
    }
  }

  .content-item-text-wrapper {
    border: 1px solid $content-item-text-border-color;
    border-radius: 0 0 $default-radius $default-radius;
    border-top: none;
    padding: .75em;
    line-height: 1;
  }

  .content-item-image-wrapper {
    background-color: $gray-800;
    border: 1px solid $content-item-text-border-color;
    border-bottom: none;
    height: $thumbnail-max-height;
    width: $thumbnail-max-width;
    position: relative;
  }

  .display-content-src {
    height: 100%;
  }

  .content-item-image-overlay {
    background-color: transparentize($white, .2);
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .actions-wrapper {
    height: 100%;
    width: 100%;

    /* Flex Styles */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .action-button {
      min-width: 130px;
      flex: 0 0 auto;
      padding: .25em 1em;
    }
  }

  .content-menu {
    position: absolute;
    right: .5em;
    top: .5em;
  }

  .menu-button {
    @include w24-transparent-button;
    font-size: 1.5em;
    color: $black;
  }
}
