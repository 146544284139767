@import 'variables';

.new-clip-header {
  margin-bottom: 1.5em;

  /* Flex Styling */
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-buttons {
    width: 100%;

    /* Flex Styling */
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .control-btn {
    margin-left: 1em;
    padding: .5em .75em;
    font-size: .875em;
  }

  .blank-canvas-btn {
    border-color: $gray-400;
    color: $gray-400;
  }
}
