@import 'variables';

$dark-bg-color: $gray-800;
$width-of-left-bar: 250px;
$width-of-right-bar: 250px;
$total-bar-width: $width-of-left-bar + $width-of-right-bar;

#editor-page {
  background: center/11px url("../../../assets/images/display-bg.png") repeat;
  overflow: hidden;
  height: 100%;

  /* Flex Styles */
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;

  #editor-sidebar {
    flex: 0 0 $width-of-left-bar; // don't grow, don't shrink, start at 250px;
  }

  #display-sidebar {
    &.is-minimized {
      flex: 0 0 38px; // don't grow, don't shrink, start at 38px;
    }

    &.is-expanded {
      flex: 0 0 $width-of-right-bar; // don't grow, don't shrink, start at 250px;
    }
  }

  .main-display {
    flex: 2 0 auto;
    height: 100%;
    width: calc(100vw - #{$total-bar-width});
    position: relative;
  }

  .display-controls {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    border: 1px solid $gray-900;
    border-bottom: none;;
    height: 50px;
    display: flex;
    justify-content: center;
  }

  .display-controls {
    .controls-wrapper {
      height: 100%;
      width: 250px;
      padding: 0;
    }
  }
}
