$button-font-size: 1.25em;

.category-add-button {
  height: 100%;

  .fa,
  .fad {
    font-size: $button-font-size;
  }

  .loading-icon {
    font-size: $button-font-size;
  }

  .btn {
    padding: 0.25rem 0.65rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
