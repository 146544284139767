@import 'variables';

$max-image-height: 14em;

.edit-image-controls {
  .can-not-edit {
    margin-top: 1rem;
    text-align: center;
  }

  .image-selector {
    margin-bottom: 1rem;
    position: relative;
  }

  .image-wrapper {
    text-align: center;
  }

  .image-change-button {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    padding: 0;
  }

  .direct-change-button {
    margin-top: .35em;
  }

  .image-thumbnail {
    width: auto;
    max-width: 220px;
    max-height: $max-image-height;
    border-radius: $default-radius;
  }

  .image-edit-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .fa,
    .fad {
      background-color: $black;
      border-radius: 100%;
      color: $white;
      padding: 10px;
      opacity: 0.75;
    }
  }

  .crop-dropdown {
    width: 20px;
    padding-right: 0;
    padding-left: 0;
  }
}
