#display-source {
  // The drop positioning will not work without 100% height.
  height: 100%;

  // We want to transform from the left so the x and y axis transformations match.
  transform-origin: left top;

  user-select: none;

  // Override the reboot overflow styling.
  svg:not(:root) {
    overflow: visible;
  }
}
