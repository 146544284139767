@import 'variables';

#employee-client-dropdown {
  .client-input-wrapper {
    padding: .5em;
  }

  .client-input {
    width: 100%;
  }

  .status-text {
    padding: .25em .75em;
    color: $gray-500;
    font-size: .875em;
    font-style: italic;
    margin: 0;
  }
}
