.preview-content-modal {
  .modal-content {
    padding: 24px;
  }

  .modal-body-content {
    padding-top: 1em;
  }

  .display-content-src {
    min-height: 100px;

    .content-empty {
      height: 200px;
    }
  }
}
