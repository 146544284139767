@import 'variables';

$modal-width: 75vw;
$modal-height: 82vh;
$upper-height: 75px;

.select-image-modal {
  max-width: $modal-width;

  .modal-content {
    width: $modal-width;
    padding: 0;
  }

  .modal-header {
    padding: 1em;
    border-bottom: 1px solid $gray-900;
  }

  .modal-body-content-wrapper {
    display: flex;
    height: $modal-height;
  }

  .content-directory-sidebar {
    flex: 0 0 300px; // don't grow, don't shrink, start at 300px;
  }

  .select-image-main {
    display: flex;
    justify-content: space-between;
    padding: .75rem 0;
    width: 100%;
  }

  .content-row {
    max-height: calc(#{$modal-height} - #{$upper-height} - .75rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
