@import 'variables';
@import 'mixins/w24-transparent-button';

@mixin base-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin active-category {
  color: $teal;
  text-decoration: none;

  .category-item-top-item-icon {
    color: $teal;
  }
}

.category-item-top-item {
  display: block;
  position: relative;
  padding: .75em .5em .75em 1em;
  border-bottom: 1px solid $gray-900;
  &:last-child {
    border-bottom: none;
  }
  font-size: .875em;

  .category-item-top-item-text {
    display: flex;
    justify-content: space-between;
  }

  .category-item-top-item-link,
  .category-item-sub-item-link {
    @include w24-transparent-button;
    text-align: left;
    display: flex;
    color: $white;

    &:focus {
      outline: none;
    }

    &:hover, &:active, &.active {
      @include active-category;
    }
  }

  .category-item-top-item-icon {
    margin-right: 1em;
    color: $gray-500;
    width: 1em;
    line-height: inherit;
  }

  .category-item-top-item-name {
    margin: 0;
  }

  .category-item-sub {
    @include base-list;
  }

  .category-item-sub-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      .category-item-sub-item-link:before {
        height: 50%;
      }
    }
  }

  .category-item-sub-item-link {
    display: inline-block;
    color: $white;
    margin-left: 2.5em;
    padding: .25em 0;
    position: relative;
    line-height: 1.5em;

    &:before, &:after {
      content: '';
      position: absolute;
      left: -1.6em;
    }

    &:before {
      border-left: 1px solid $gray-500;
      height: 100%;
      width: 0;
      top: 0;
    }

    &:after {
      border-bottom: 1px solid $gray-500;
      height: 2px;
      width: .75em;
      top: .875em;
    }
  }

  .category-action-buttons {
    text-align: right;
    color: $gray-400;
    display: flex;
    align-items: flex-start;
    margin-right: .5rem;

    .fa,
    .fad {
      font-size: 1.2em;
    }
  }

  .category-actions-open {
    display: inline-block;
  }

  .btn-icon {
    color: inherit;
    padding: 0 .5rem 0 .5rem;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}
