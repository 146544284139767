@import 'variables';

@mixin w24-transparent-button {
  background-color: transparent;
  color: $white;
  border: none;
  &:hover {
    cursor: pointer;
  }
}
