@import 'variables';

.select-category-modal {
  .content-directory-listing {
    max-height: 300px;
    overflow: auto;
  }

  .content-directory {
    border: 2px solid $gray-900;
  }
}
