@import 'variables';

.library-dropdown {
  .library-dropdown-open {
    background-color: $gray-700;
    border-radius: 0;
    color: $white;
    height: $dropdown-button-height;
    padding: 1em;
    text-align: left;
    width: 100%;

    // Flex styles.
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus {
      background-color: darken($gray-700, 1%);
    }

    &:after {
      color: $gray-400;
      font-size: 1.2em;
    }
  }

  .dropdown-menu {
    width: 100%;
  }
}
