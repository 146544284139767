@import '_bootstrap';

.editor-entity-list-item {
  position: relative;
  user-select: none;
  padding: 1rem .75rem 1rem 1.25rem;
  min-height: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &.active {
    // Reboot is preventing this effect without !important, but I don't know why.
    color: $gray-900 !important;

    .entity-name {
      color: $gray-900;
    }

    .badge {
      border-color: $gray-800;
    }
  }

  .entity-order {
    margin-right: 1em;
  }

  .entity-name-wrapper {
    max-width: 160px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .entity-order {
    }
    .entity-name {
      display: inline-block;
      max-width: 120px;
      word-wrap: break-word;
      line-height: 1.25;
    }
  }

  .entity-name {
    color: $gray-400;
  }

  .dropdown-item {
    .fa,
    .fad {
      margin-right: 1rem;
      text-align: center;
      width: 16px;
    }
  }

  .entity-actions-wrapper {
    display: flex;
    align-items: center;
  }

  .entity-actions {
    .btn-link {
      position: relative;
      top: 1px;
      color: $gray-900;
    }
  }

  .entity-reorder-action {
    cursor: grab;
    color: $gray-900;
    margin-left: .5rem;
  }
}

// In IE and Edge, change the padding to account for scrollbar width.
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  $ie-right-size: 10px;

  .editor-entity-list-item {
    .entity-actions-wrapper {
      margin-right: $ie-right-size;
    }
  }
}
