@import 'variables';
@import 'mixins/w24-editor-fonts';

@include w24-editor-font-faces;

#display {
  width: 100%;
  //height: 362px;
  overflow: hidden;

  .scrollbars {
    .scrollbar-horizontal {
      width: calc(100% - 27px);
      margin-left: 7px;
    }

    .scrollbar-vertical {
      height: calc(100% - 27px);
      margin-top: 7px;
    }
  }
}
