.confirm-modal {
  .modal-footer {
    margin-top: 1em;
  }
  small {
    display: block;
    line-height: 1.3;
    font-size: 14px;
  }
}
