@import 'variables';

.dashboard-figure {
  padding: 0 2em;
  margin-bottom: 2em;

  .figure-image {
    display: block;
    padding: 0 1em;
  }

  .figure-button {
    margin: 0 1em;
    width: auto;
  }
}
