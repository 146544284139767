@import "variables";

.display-control {
  background-color: $gray-600;
  height: 100%;
  padding: 0 1rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    font-size: 1.5rem;
  }

  .control-btn {
    padding: 0;
    color: white;

    &.disabled {
      opacity: .2;
    }
  }
}
