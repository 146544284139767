@import 'mixins/w24-heading';

#dashboard-page {
  text-align: center;
  padding: 2em;

  .page-heading {
    @include w24-heading;
  }
}
