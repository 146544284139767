@import "variables";

#content-page {

  .page-content {
    // Full height minus content header and main nav bar
    height: calc(100vh - #{$content-header-height} - #{$main-nav-bar-height});
    display: flex;
    flex-direction: row;
  }

  .page-sidebar {
    flex: 0 0 300px; // don't grow, don't shrink, start at 300px;
    height: 100%;
  }

  .page-heading {
    font-weight: 300;
    font-size: 1.25em;
    margin: 1em 0;

    /* Flex Styling */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading-title {
    flex: 0 1 auto;
    line-height: inherit;
    margin-bottom: 0;
  }

  .heading-actions {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
  }

  .action-control-group {
    margin: 0 .5em;

    /* Flex Styling */
    display: flex;
    align-items: center;
  }

  .refresh-button {
    font-size: 1.25em;
    font-weight: bold;
  }

  .toggle-label {
    font-size: 0.8em;
    font-weight: 300;
    margin: .5em .5em .5em 1em;
    cursor: pointer;
  }

  .page-main {
    height: 100%;
    overflow: auto;
    padding: 0 30px;
  }
}
