@import 'variables';

#login-form {
  margin-top: 2em;

  @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    margin-top: 0;
  }

  .form-header {
    margin-bottom: 4em;
    text-align: center;
  }

  .main-header {
    font-weight: 100;
  }

  .sub-header {
    font-weight: 100;
    font-size: .9em;
    letter-spacing: 1px;
    margin-top: 1em;
    text-transform: uppercase;
  }

  .login-form-footer {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
  }

  #remember-me {
    margin-right: 1em;
  }

  .btn-submit {
    margin-bottom: 1em;
  }

  .remember-me-wrapper {
    display: flex;
    align-items: center;
  }

  .remember-me-label {
    margin: 0 0 0 1em;
    cursor: pointer;
  }
}
