@import 'variables';

.playback-controls {
  background-color: $gray-600;
  height: 53px;
  margin-right: 1px;
  margin-left: 1px;
  padding: .25em 1em .25em .75em;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  /* Flex Styles */
  display: flex;
  align-items: center;
  justify-content: space-between;

  .player-controls-wrapper {
    width: 85px;
  }
  .time-control-wrapper {
    width: calc(100% - 150px);
  }
  .time-tracker-wrapper {
    width: 55px;
  }

  .controls-wrapper {
    flex: 0 1 auto;
    min-width: 55px;
    padding: 0;
  }

  .timer {
    text-align: right;
  }
}
