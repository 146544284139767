#editor-nav-item {
  display: flex;

  .content-name {
    padding-left: .75em;
    margin: 0;
  }

  .content-image {
    height: 2em;
    width: 2em;
    border-radius: 50%;
  }
}
