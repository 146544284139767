@import 'mixins/w24-transparent-button';

.w24-refresh-button {
  @include w24-transparent-button;
  transition: all .25s ease;
  transform: rotate(0deg);
  color: $gray-400;
  font-size: 1.5em;
  &:hover {
    color: $gray-500;
    transform: rotate(20deg);
  }
  &:focus {
    outline: none;
  }
}
