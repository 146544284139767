@import 'variables';

.entity-nav {
  border: 1px solid $gray-900;
  border-radius: $default-radius;

  .btn {
    font-size: 1.2rem;
    line-height: 1rem;
  }

  .btn:last-child {
    border-left: 1px solid $gray-900;
  }
}
