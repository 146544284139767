@import 'variables';

.scrollbars {
  height: 100%;
  position: relative;

  .scrollbar-horizontal,
  .scrollbar-vertical {
    position: absolute;

    .single-slider-track {
      border-radius: 6px;
      opacity: 0;
      transition: background-color .2s linear, opacity .2s linear;
    }

    .single-slider-handle {
      background-color: $gray-300;
      border-radius: 6px;
      opacity: 0;
      transition: background-color .2s linear, opacity .2s linear, width .2s ease-in-out;
    }
  }

  &:active,
  &:focus,
  &:hover {
    .single-slider-track {
      opacity: .4;
    }

    .single-slider-handle {
      opacity: .8;
    }
  }

  .scrollbar-horizontal {
    bottom: 0;
    left: 0;
    z-index: 1000;

    .single-slider-track {
      height: 14px;
    }
    .single-slider-handle {
      top: 9px;
      height: 14px;
    }
  }

  .scrollbar-vertical {
    top: 0;
    right: 0;
    z-index: 1001;

    .single-slider-track {
      width: 14px;
    }
    .single-slider-handle {
      left: 9px;
      width: 14px;
    }
  }
}
