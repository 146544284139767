@import 'variables';

$page-padding: 2em;

#new-clip-type-page {
  height: 100%;
  padding: $page-padding;
  padding-bottom: 0;
  overflow-y: auto;
  text-align: center;

  .new-clip-type-page-wrapper {
    display: flex;
    // Full height minus page padding and dashboard header
    min-height: calc(100vh - #{$page-padding} - #{$dashboard-header-height});
  }

  .new-clip-type-page-content-dir {
    border: 2px solid $gray-900;
    border-right: none;
    border-bottom: none;
    flex: 0 0 300px; // don't grow, don't shrink, start at 300px;
  }

  .new-clip-type-page-main {
    border: 2px solid $gray-900;
    border-left: none;
    border-bottom: none;
    width: 100%;
    padding: 1em 2em;
  }
}
