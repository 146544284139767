@import 'variables';

.zoom-control {
  background-color: $gray-600;
  border-left: 1px solid $gray-900;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .5rem;
  user-select: none;

  .zoom-icon {
    &:first-of-type {
      margin-right: .25rem;
    }
    &:last-of-type {
      margin-left: .25rem;
    }
  }

  .zoom-slider {
    margin: 0 .75em;
    max-width: 100%;
    width: 100%;
  }
}
