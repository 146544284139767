@import 'variables';
@import 'mixins/w24-transparent-button';

.clip-actions {
  display: inline;

  .clip-actions-open {
    @include w24-transparent-button;
    color: $black;
  }

  .dropdown {
    display: inline;
  }

  .dropdown-item {
    .fa,
    .fad {
      margin-right: .5em;
      width: 20px;
    }
  }

  .dropdown-filler {
    display: inline-block;
  }
}
