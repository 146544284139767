/**
 * Loads all the fonts needed for the editor.
 */

$w24-editor-fonts: (
  amaticsc: (AmaticSC, sans-serif),
  architectsdaughter: (ArchitectsDaughter, sans-serif),
  arvo: (Arvo, sans-serif),
  centurygothic: (CenturyGothic, sans-serif),
  fondamento: (Fondamento, sans-serif),
  greatvibes: (GreatVibes, sans-serif),
  gothamcondensedmedium: (GothamCondensedMedium, sans-serif),
  jura: (Jura, sans-serif),
  lifesavers: (LifeSavers, sans-serif),
  lora: (Lora, sans-serif),
  merienda: (Merienda, sans-serif),
  merriweather: (Merriweather, sans-serif),
  montserrat: (Montserrat, sans-serif),
  oswald: (Oswald, sans-serif),
  ptsans: (PTSans, sans-serif),
  pacifico: (Pacifico, sans-serif),
  permanentmarker: (PermanentMarker, sans-serif),
  playball: (Playball, sans-serif),
  playfairdisplay: (PlayfairDisplay, sans-serif),
  proximanova: (ProximaNova, sans-serif),
  proximanovablack: (ProximaNovaBlack, sans-serif),
  proximanovasemibold: (ProximaNovaSemibold, sans-serif),
  quicksand: (Quicksand, sans-serif),
  roboto: (Roboto, sans-serif),
  robotocondensed: (RobotoCondensed, sans-serif),
  robotoslab: (RobotoSlab, sans-serif),
  rosario: (Rosario, sans-serif),
  rubik: (Rubik, sans-serif),
  sancreek: (Sancreek, sans-serif),
  sourcesanspro: (SourceSansPro, sans-serif),
  specialelite: (SpecialElite, sans-serif),
  yellowtail: (Yellowtail, sans-serif),
);

@mixin w24-editor-font-faces {

  /* AmaticSC */
  @font-face {
    font-family: 'AmaticSC';
    src: url('../../../assets/fonts/AmaticSC-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'AmaticSC';
    src: url('../../../assets/fonts/AmaticSC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* ArchitectsDaughter */
  @font-face {
    font-family: 'ArchitectsDaughter';
    src: url('../../../assets/fonts/ArchitectsDaughter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Arvo */
  @font-face {
    font-family: 'Arvo';
    src: url('../../../assets/fonts/Arvo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Arvo';
    src: url('../../../assets/fonts/Arvo-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Arvo';
    src: url('../../../assets/fonts/Arvo-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Arvo';
    src: url('../../../assets/fonts/Arvo-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* CenturyGothic */
  @font-face {
    font-family: 'CenturyGothic';
    src: url('../../../assets/fonts/CenturyGothic-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'CenturyGothic';
    src: url('../../../assets/fonts/CenturyGothic-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* Fondamento */
  @font-face {
    font-family: 'Fondamento';
    src: url('../../../assets/fonts/Fondamento-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Fondamento';
    src: url('../../../assets/fonts/Fondamento-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* GreatVibes */
  @font-face {
    font-family: 'GreatVibes';
    src: url('../../../assets/fonts/GreatVibes-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Gotham */
  @font-face {
    font-family: 'GothamCondensedMedium';
    src: url('../../../assets/fonts/GothamCondensedMedium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Jura */
  @font-face {
    font-family: 'Jura';
    src: url('../../../assets/fonts/Jura-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Jura';
    src: url('../../../assets/fonts/Jura-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* LifeSavers */
  @font-face {
    font-family: 'LifeSavers';
    src: url('../../../assets/fonts/LifeSavers-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'LifeSavers';
    src: url('../../../assets/fonts/LifeSavers-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* Lora */
  @font-face {
    font-family: 'Lora';
    src: url('../../../assets/fonts/Lora-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lora';
    src: url('../../../assets/fonts/Lora-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lora';
    src: url('../../../assets/fonts/Lora-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lora';
    src: url('../../../assets/fonts/Lora-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* Merienda */
  @font-face {
    font-family: 'Merienda';
    src: url('../../../assets/fonts/Merienda-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Merienda';
    src: url('../../../assets/fonts/Merienda-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* Merriweather */
  @font-face {
    font-family: 'Merriweather';
    src: url('../../../assets/fonts/Merriweather-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Merriweather';
    src: url('../../../assets/fonts/Merriweather-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Merriweather';
    src: url('../../../assets/fonts/Merriweather-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Merriweather';
    src: url('../../../assets/fonts/Merriweather-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* Montserrat */
  @font-face {
    font-family: 'Montserrat';
    src: url('../../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('../../../assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('../../../assets/fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('../../../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* Oswald */
  @font-face {
    font-family: 'Oswald';
    src: url('../../../assets/fonts/Oswald-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Oswald';
    src: url('../../../assets/fonts/Oswald-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* PTSans */
  @font-face {
    font-family: 'PTSans';
    src: url('../../../assets/fonts/PTSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PTSans';
    src: url('../../../assets/fonts/PTSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'PTSans';
    src: url('../../../assets/fonts/PTSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'PTSans';
    src: url('../../../assets/fonts/PTSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* Pacifico */
  @font-face {
    font-family: 'Pacifico';
    src: url('../../../assets/fonts/Pacifico-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* PermanentMarker */
  @font-face {
    font-family: 'PermanentMarker';
    src: url('../../../assets/fonts/PermanentMarker-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Playball */
  @font-face {
    font-family: 'Playball';
    src: url('../../../assets/fonts/Playball-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* PlayfairDisplay */
  @font-face {
    font-family: 'PlayfairDisplay';
    src: url('../../../assets/fonts/PlayfairDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PlayfairDisplay';
    src: url('../../../assets/fonts/PlayfairDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'PlayfairDisplay';
    src: url('../../../assets/fonts/PlayfairDisplay-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'PlayfairDisplay';
    src: url('../../../assets/fonts/PlayfairDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* ProximaNova */
  @font-face {
    font-family: 'ProximaNova';
    src: url('../../../assets/fonts/ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* ProximaNovaBlack */
  @font-face {
    font-family: 'ProximaNovaBlack';
    src: url('../../../assets/fonts/ProximaNovaBlack-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* ProximaNovaSemibold */
  @font-face {
    font-family: 'ProximaNovaSemibold';
    src: url('../../../assets/fonts/ProximaNovaSemibold-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Quicksand */
  @font-face {
    font-family: 'Quicksand';
    src: url('../../../assets/fonts/Quicksand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Quicksand';
    src: url('../../../assets/fonts/Quicksand-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* Roboto */
  @font-face {
    font-family: 'Roboto';
    src: url('../../../assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('../../../assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('../../../assets/fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('../../../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* RobotoCondensed */
  @font-face {
    font-family: 'RobotoCondensed';
    src: url('../../../assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'RobotoCondensed';
    src: url('../../../assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'RobotoCondensed';
    src: url('../../../assets/fonts/RobotoCondensed-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'RobotoCondensed';
    src: url('../../../assets/fonts/RobotoCondensed-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* RobotoSlab */
  @font-face {
    font-family: 'RobotoSlab';
    src: url('../../../assets/fonts/RobotoSlab-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'RobotoSlab';
    src: url('../../../assets/fonts/RobotoSlab-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* Rosario */
  @font-face {
    font-family: 'Rosario';
    src: url('../../../assets/fonts/Rosario-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rosario';
    src: url('../../../assets/fonts/Rosario-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rosario';
    src: url('../../../assets/fonts/Rosario-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Rosario';
    src: url('../../../assets/fonts/Rosario-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* Rubik */
  @font-face {
    font-family: 'Rubik';
    src: url('../../../assets/fonts/Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rubik';
    src: url('../../../assets/fonts/Rubik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rubik';
    src: url('../../../assets/fonts/Rubik-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Rubik';
    src: url('../../../assets/fonts/Rubik-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* Sancreek */
  @font-face {
    font-family: 'Sancreek';
    src: url('../../../assets/fonts/Sancreek-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* SourceSansPro */
  @font-face {
    font-family: 'SourceSansPro';
    src: url('../../../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SourceSansPro';
    src: url('../../../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'SourceSansPro';
    src: url('../../../assets/fonts/SourceSansPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'SourceSansPro';
    src: url('../../../assets/fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* SpecialElite */
  @font-face {
    font-family: 'SpecialElite';
    src: url('../../../assets/fonts/SpecialElite-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Yellowtail */
  @font-face {
    font-family: 'Yellowtail';
    src: url('../../../assets/fonts/Yellowtail-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}
