@import 'variables';

$dark-bg-color: $gray-800;
$dark-color: #7F7F7F;

.w24-control-editor {
  input.form-control {
    background-color: $gray-700;
    border-color: $gray-700;
    border-radius: $default-radius;
    color: $white;
    padding: 7px;
  }

  .sidebar-title {
    font-size: 1.2rem;
    margin-top: .5rem;
  }

  .group-header {
    background-color: $dark-bg-color;
    margin-left: -15px;
    margin-right: -15px;
    padding: .5rem;
  }

  .group-header-label {
    color: $dark-color;
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 15px;
  }

  .group-controls {
    padding: 1rem 0;
  }

  .form-buttons {
    display: flex;
    justify-content: space-between;
  }

  .form-button {
    width: 36px;
    height: 36px;
  }
}
