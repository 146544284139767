@import 'variables';

#login-page {
  .login-page-bg-image {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (max-width: map-get($grid-breakpoints, "md")) {
      display: none;
    }
  }

  .login-col-right {
    position: relative;
  }

  .login-page-content {
    padding: 6em 5em;
    @media screen and (max-width: map-get($grid-breakpoints, "md")) {
      padding: 5em;
    }
    max-width: 600px;
    margin: auto;
  }

  .full-height > .loading-icon {
    height: 100%;
    margin: 0;
  }

  .bottom-logo {
    .login-page-logo {
      width: 150px;
      position: absolute;
      bottom: 2em;
      right: 3em;
    }
  }
}
