@import 'variables';

#content-header {
  display: flex;
  height: $content-header-height;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-900;
  background-color: $gray-900;

  .sub-header {
    display: flex;
    align-items: center;
  }

  .sub-header-main {
    width: 100%;

    .search-form {
      font-size: 1.25em;
    }
  }

  .header-control-group {
    display: flex;
    align-items: center;
    margin: .75em;
    margin-right: calc(30px + 1em);
  }

  .sub-header-logo {
    display: flex;
    align-items: center;
    min-width: 300px;
  }

  .sub-header-search-wrapper {
    margin-left: 1em;
    width: 100%;

    .search-form {
      width: 100%;
      max-width: 400px;
    }
  }

  .logo {
    width: 70px;
    margin: 0 .5em;
  }

  .sub-header-title {
    font-size: 1.375em;
    margin: .5em 0;
    font-weight: 300;
  }

  .header-label {
    font-size: 0.9em;
    font-weight: 300;
    margin: .5em .5em .5em 1em;
    cursor: pointer;
  }
}
