@import '_bootstrap';
@import 'variables';

$title-height: 65px;
$actions-height: 80px;
$total-height: $title-height + $actions-height;

#editor-sidebar {
  background-color: $gray-800;
  height: 100%;
  box-shadow: 1px 0 7px 0 rgba($black ,0.3);

  .sidebar-top {
    padding: .75rem 1rem;
    height: $title-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-title {
    font-size: 1.2rem;
    margin-top: .5rem;
  }

  .sidebar-controls {
    .btn-sm {
      width: 33px;
    }

    .is-disabled {
      .fa,
      .fad {
        opacity: .2;
      }
    }
  }

  .entity-nav {
    margin-right: 1rem;
  }

  .sidebar-wrapper {
    height: calc(100% - #{$total-height});
    overflow-y: auto;
  }

  .sidebar-tab-controls {
    padding-top: 1em;
  }

  .sidebar-actions {
    height: $actions-height;
    display: flex;
    align-items: center;
    padding: 0 1em;
    .btn {
      margin: 0 auto;
    }
  }

  .dropdown-button {
    background-color: darken($teal, 10);
    border-color: darken($teal, 10);
    border-top-right-radius: $btn-border-radius-lg;
    border-bottom-right-radius: $btn-border-radius-lg;
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .dropdown-button-text {
    margin-left: 1em;
  }
}
