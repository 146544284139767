.category-actions {
  display: inline;

  .dropdown {
    display: inline;
  }

  .dropdown-item {
    .fa,
    .fad {
      margin-right: .5em;
      width: 20px;
    }
  }

  .dropdown-filler {
    display: inline-block;
  }
}
