@import 'variables';
@import 'mixins/w24-heading';

#product-selection-page {
  text-align: center;

  .page-heading {
    @include w24-heading;
  }

  .page-items {
    margin-top: 1em;
  }

  .full-width {
    width: 100%;
  }

  .page-error {
    width: 100%;
    text-align: center;
  }

  .retry-btn {
    padding-left: 3em;
    padding-right: 3em;
  }
}
