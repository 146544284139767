@import 'variables';
@import 'mixins/w24-heading';

@mixin dashboard-header-link {
  position: absolute;
  top: 0;
  color: $gray-400;
  font-size: 2em;
  &:hover, &:focus {
    text-decoration: none;
  }
}

#dashboard-header {
  position: relative;
  height: $dashboard-header-height;

  .dashboard-header-logo {
    width: 60px;
    display: block;
    margin: 0 auto;
  }

  .dashboard-header-title {
    @include w24-heading;
  }

  .dashboard-header-close {
    @include dashboard-header-link;
    right: 0;
  }

  .dashboard-header-back {
    @include dashboard-header-link;
    left: 0;
  }
}
