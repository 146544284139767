@import 'mixins/stroke';
@import 'variables';

.display-content-src {
  overflow: hidden;
  position: relative;
  width: 100%;

  /* Flex Styles */
  display: flex;
  align-content: flex-start;
  justify-content: center;

  .content-main {
    width: 100%;

    /* Flex Styles */
    flex: 0 0 auto;
    align-self: center;
  }

  .content-empty {
    background-color: $gray-400;
  }

  .content-image {
    height: 100%;
  }

  .processing-title {
    @include stroke(2, $black);

    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  &.is-processing {
    .content-image,
    .content-video {
      opacity: .5;
    }
  }

  &.stretch-content {
    .content-main {
      align-self: stretch;
    }
  }
}
