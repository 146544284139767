@import 'variables';

@mixin base-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-directory {
  border-right: 2px solid $gray-900;
  height: 100%;
  text-align: left;

  .content-directory-listing {
    @include base-list;

    border-top: 1px solid $gray-900;
    // Full height minus library button and search wrapper
    height: calc(100% - #{$dropdown-button-height} - #{$search-wrapper-height});
    overflow-y: auto;
    overflow-x: hidden;
  }

  .content-directory-search-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 1em;
    height: $search-wrapper-height;
  }

  .add-category-wrapper {
    height: 100%;
    margin-left: .75em;
  }

  .search-form-wrapper {
    flex: 1 1 auto;
    border-radius: $default-radius;
    border: 1px solid $gray-600;
    padding: .25em .5em;
  }
}
