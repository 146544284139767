@import 'variables';

$header-height: 50px;
$footer-height: 60px;

#main-layout {
  height: 100%;
  overflow-y: hidden;

  /* Flex Styling */
  display: flex;
  flex-direction: column;
}

#main-content {
  display: flex;
  height: calc(100% - #{$header-height});
}

#main-sidebar {
  flex: 0 0 300px; // don't grow, don't shrink, start at 300px;
}

#main-page {
  width: 100%;
  height: 100%;
}
