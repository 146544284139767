@import 'variables';
@import 'mixins/w24-transparent-button';

.clip-item {
  cursor: pointer;
  display: flex;
  margin: 0 0 1.5rem;
  position: relative;
  width: 100%;
  height: 100%;

  &:focus,
  &:hover {
    .clip-item-overlay {
      display: flex;
    }
  }

  .display-content-src {
    border-radius: 2%;
    height: 100%;
  }

  .clip-item-overlay {
    cursor: pointer;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparentize($white, .2);
    border: 4px solid $primary;
    border-radius: 2%;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    &:active,
    &:focus,
    &:hover {
      display: flex;
    }
  }

  .clip-item-overlay-text {
    color: $gray-800;
    background-color: $teal;
    border-radius: $default-radius;
    display: inline-block;
    font-size: .875em;
    margin: 0 .5em;
    padding: .5em 1.25em;
  }

  .content-menu {
    position: absolute;
    right: 0;
    top: 0;
  }

  .menu-button {
    @include w24-transparent-button;
    font-size: 1.5em;
    color: $black;
  }
}
