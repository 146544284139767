#render-page {
  background-color: transparent;
  height: 100%;

  .render-display {
    background-color: transparent;
    height: 100%;

    #display {
      border: 0;
    }
  }

  #display-source {
    // Hides flyIn/flyOut transitions from showing.
    overflow: hidden;
  }
}
