@import "variables";

/* Styles */
body,
html,
#app,
.app-wrapper {
  height: 100%;

  // Prevent highlighting of things in IE11.
  -ms-user-select: none;
}

body {
  background-color: $body-bg-color;
  color: $body-color;
  font-family: 'Heebo', sans-serif;
  line-height: 1.8;
  position: relative;
}

img {
  max-width: 100%;
}

a {
  color: $teal;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

.full-height {
  height: 100%;
}

.center-align {
  text-align: center;
}
