@import 'variables';

@import 'modules/w24-control-editor';
@import 'modules/w24-react-select';
@import 'modules/w24-react-toggle';
@import 'modules/w24-refresh-button';
@import 'modules/w24-no-results';
@import 'modules/w24-error';
@import 'modules/w24-text-input';
@import 'modules/w24-label';
@import 'modules/w24-transparent-button';
@import 'modules/w24-navbar-control'; // This must be below w24-transparent-button.
@import 'browserSupport';

body {
  -webkit-font-smoothing: antialiased;
}

.main-row {
  padding: 1rem 1rem;
}

.sub-row {
  padding: 1rem 1rem;
}

a {
  cursor: pointer;
}

* {
  &:focus {
    outline: none !important;
  }
}

.btn-icon {
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.icon-bold {
  font-weight: bold;
}

/*
 * BADGES
 */

.badge-outline-primary {
  background-color: $badge-outline-bg-color;
  border: 1px solid $primary;
  color: $primary;
}

.badge-outline-dark {
  background-color: $badge-outline-bg-color;
  border: 1px solid $gray-400;
  color: $gray-400;
}

// Makes a badge-pill with only one character inside into a circle.
.badge-pill-circle {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border-radius: 50%;
}

/*
 * Tooltips
 */
.tooltip {
  .tooltip-inner {
    hr {
      border-top-color: $white;
    }
  }
}
