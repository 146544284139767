@import '_bootstrap';
@import 'variables';

#main-navbar {
  border-bottom: $navbar-border-height solid $gray-900;
  height: $navbar-content-height;

  /* Flex Styling */
  flex: 0 0 $main-nav-bar-height;
  display: flex;
  justify-content: space-between;

  .navbar-left,
  .navbar-right,
  .navbar-center {
    display: flex;
    align-items: center;
  }

  .navbar-logo {
    min-width: 45px;
    padding: 11px .75em;
  }

  .logo-img {
    width: 40px;
  }

  .user-controls {
    .w24-navbar-control {
      border-left: none;
    }
  }
}
