@import 'variables';

.entity-add-button {
  display: inline-block;

  .btn {
    border: 1px solid $gray-900;
    border-radius: $default-radius;
  }

  .child-dropdown {
    overflow: unset !important;
  }

  .dropdown-item {
    .fa,
    .fad {
      margin-right: 1rem;
      text-align: center;
      width: 16px;

      &.pull-right {
        margin-right: -.75rem;
        margin-top: .25rem;
        width: auto;
      }
    }
  }
}

// In IE and Edge, change the padding to account for scrollbar width.
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .entity-add-button {
    .dropdown-item {
      overflow: visible !important;
    }
  }
}
