@import '~line-awesome/src/variables';
@import 'variables';

.color-selector {
  .rc-color-picker-trigger {
    border: 1px solid $gray-500;
    border-radius: $default-radius;
    box-shadow: 0 0 0 white;
    position: relative;
    overflow: hidden;
    width: 36px;
    height: 36px;

    &::after {
      content: la-content($la-caret-down);
      position: absolute;
      bottom: 0;
      right: 0;
      font: normal normal normal #{$la-font-size-base}/#{$la-line-height-base} $la-font-name;
      padding: 2px 3px 1px 3px;
      font-size: .75em;
      line-height: .8;
      background-color: $gray-500;
    }
  }
}

.pcr-app {
  background: $gray-900;
  border: 1px solid $gray-500;
  box-shadow: 0 0 0 white;
  padding: 1rem 1rem 2rem;
}
