@import '_bootstrap';
@import 'variables';

.feed-dropdown {
  display: flex;
  align-items: center;

  .w24-select {
    margin-left: 10px;
    width: 200px;
  }
}
