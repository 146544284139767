@import 'variables';

.product-item {
  text-align: center;
  padding: 0 1em;

  .item-icon-wrapper {
    cursor: pointer;
    padding: 2em 1em;
    margin-bottom: 2em;
  }

  .item-icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 6em;
  }

  .item-icon-poster {
    background-image: url('../../../../assets/images/poster-icon.svg');
  }

  .item-icon-multi-screen {
    background-image: url('../../../../assets/images/multi-screen-icon.svg');
  }

  .item-icon-video {
    background-image: url('../../../../assets/images/video-icon.svg');
  }

  .item-button {
    white-space: pre-wrap;
  }
}
