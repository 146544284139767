// Bootstrap grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

/*=== Colors ===*/
$w24-red: #d72224;
$teal: #50D2C2;
$red: #D9534F;
$yellow: #D9AB4F;
$white: #fff;
$template-color: #402020;
$my-content-color: #20403C;
$error: #CB5955;
$focus: #0099E0;

// Default bootstrap grays
$gray-100: #f8f9fa;
$gray-200: #e9ecef;

// Custom grays
$gray-200: #ECF5FD;
$gray-300: #D8D8D8;
$gray-400: #999999;
$gray-500: #6D6D6D;
$gray-600: #505050;
$gray-700: #3F3F3F;
$gray-800: #333333;
$gray-900: #292929;
$black: #000;

// Custom Heights
$dashboard-header-height: 12em;
$search-wrapper-height: 4.3em;
$dropdown-button-height: 4em;
$content-header-height: 8em;
$main-nav-bar-height: 52px;

$default-radius: 4px;

// Bootstrap colors.
$primary: $teal;
$secondary: $gray-700;

$body-bg-color: $gray-800;
$body-color: $white;

// Content Item Colors
$content-item-text-bg-color: $gray-700;
$content-item-text-border-color: $gray-600;
$content-item-text-color: $gray-300;
$content-item-subtext-color: $gray-500;

// Editor ruler
$ruler-bg-color: $gray-500;
$ruler-color: $gray-400;

// Controls (Buttons, Inputs, etc.)
$control-button-bg-color: $gray-700;
$control-button-border-color: $gray-600;
$control-button-color: $gray-500;

// Player Controls
$player-controls-bg-color: $gray-500;
$player-controls-color: $white;

$player-separator-bg-color: $gray-800;

$player-zoom-bar-bg-color: $gray-600;
$player-zoom-bar-color: $gray-500;

// Badges
$badge-outline-bg-color: $gray-800;

// Timeline
$timeline-text-bg-color: $teal;
$timeline-shape-bg-color: $red;
$timeline-image-bg-color: $yellow;
$timeline-transition-bg-color: #ffffff;

$timeline-row-bg-color: $gray-700;

$timeline-button-icon-bg-color: $gray-400;
$timeline-button-bg-color: $gray-600;
$timeline-button-color: $gray-500;

$timeline-time-selector-bg-color: $gray-500;
$timeline-time-selector-color: $gray-600;

// Thumbnails
// These need to match the THUMBNAIL_MAX_HEIGHT and THUMBNAIL_MAX_WIDTH variables in the contentsHelper file.
$thumbnail-max-height: 159px;
$thumbnail-max-width: 220px;

// Nav Bar
$navbar-border-height: 2px;
$navbar-content-height: $main-nav-bar-height - $navbar-border-height;

/*
 * BOOTSTRAP OVERRIDES
 */

//** Background color on `.list-group-item`
$list-group-bg: $body-bg-color;
//** `.list-group-item` border color
$list-group-border: #ddd !default;
//** List group border radius
$list-group-border-radius: 0;

//** Background color of single list items on hover
$list-group-hover-bg: $control-button-bg-color;
//** Text color of active list items
$list-group-active-color: #ffffff;
//** Background color of active list items
$list-group-active-bg: $teal;

$input-padding-y: .75em;
$input-padding-x: 1em;
$input-placeholder-color: $gray-400;

$btn-padding-y: .75em;
$btn-padding-x: 1em;
$btn-focus-box-shadow: none;

//** Background for the dropdown menu.
$dropdown-bg: $gray-600;
//** Dropdown link text color.
$dropdown-link-color: $white;
$dropdown-link-hover-color: $white;
//** Hover background for dropdown links.
$dropdown-link-hover-bg: $gray-700;
//** The dropdown divider color.
$dropdown-divider-bg: $gray-500;

// Modal
$modal-backdrop-opacity: .9;
$modal-header-border-color: $gray-600;
$modal-footer-border-color: $gray-600;
$modal-content-bg: $body-bg-color;

//** Muted text such as on form .text-muted elements.
$text-muted: $gray-400;

// These fix a bug in bootstrap where they were 'rem' but it combined them with an 'em'.
$custom-select-indicator-padding: 1em;
$input-btn-padding-y: .375em !default;
$input-btn-padding-x: .75em !default;
